/* OLD: */
main {
  min-height: 100px;
  flex: 1 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
}
main article {
  padding-bottom: 40px;
  flex: 1 1 100%;
}
main article > *, main article > pre,
main article pre[class*=language-] {
  display: block;
  width: 100%;
}
main article code,
main article code[class*=language-], main article > pre,
main article pre[class*=language-] {
  font-family: Monaco, Consolas, courier, monospace;
  font-size: 14px;
  text-shadow: none;
  white-space: pre-wrap;
}
main article pre:has(> code) {
  white-space: pre-wrap;
  overflow-x: auto;
  max-width: 100%;
  background-color: #222;
  padding: 10px;
}
main article > pre,
main article pre[class*=language-] {
  border-radius: 0;
  padding: 10px 0;
}
main .pagebottomnav:after {
  content: "";
  display: block;
  clear: both;
}
main .pagebottomnav > a {
  display: inline-block;
  text-decoration: none;
  height: 30px;
  float: left;
  background-color: #e9e9e9;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 20px;
  width: 120px;
  text-align: right;
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 100px;
  position: relative;
  transition: background-color 0.15s;
}
main .pagebottomnav > a:hover {
  background-color: #d9d9d9;
}
main .pagebottomnav > a .fa {
  color: #3BA1FA;
  position: absolute;
  font-size: 16px;
  top: 7px;
  left: 12px;
}
main .pagebottomnav > a.nextpage {
  text-align: left;
  float: right;
}
main .pagebottomnav > a.nextpage .fa {
  left: auto;
  right: 12px;
}
@media (min-width: 1001px) {
  main aside + article {
    flex: 1 1 calc(66.666% + 20px);
    max-width: calc(66.666% + 20px);
  }
}