/* OLD: */
aside {
  flex: 1 1 calc(25% + 7px);
  max-width: calc(25% + 7px);
  position: relative;
}
aside #filter-contenlist {
  outline: none;
  -webkit-appearance: none;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #c7c7c7;
  margin: 0 0 5px;
  height: 30px;
  padding: 5px 15px;
  font-size: 15px;
  margin-top: -3px;
}
aside .manual-title {
  margin: 0 0 5px;
  font-weight: bold;
  font-size: 16px;
  border-top: 1px solid #c7c7c7;
  padding: 10px 0;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
aside .downloadpdf {
  border-top: 1px solid #c7c7c7;
  margin-top: 10px;
  padding: 10px 0;
  font-size: 15px;
  white-space: nowrap;
}
aside .downloadpdf .icon {
  font-size: 16px;
  margin-right: 7px;
}
@media (max-width: 1000px) {
  aside #filter-contenlist, aside .manual-title,
  aside .downloadpdf {
    margin-left: 20px;
    margin-right: 20px;
    max-width: calc(100% - 40px);
  }
}
aside .contentlistheader-mobile {
  display: none;
  padding: 10px 20px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  font-size: 20px;
  line-height: 20px;
  position: relative;
}
aside .contentlistheader-mobile .contentmenu-toggle {
  position: absolute;
  left: 100vw;
  top: 0;
  width: 40px;
  height: 40px;
  margin-left: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (max-width: 600px) {
  aside .contentlistheader-mobile .contentmenu-toggle {
    margin-left: -45px;
  }
}
@media (max-width: 1000px) {
  aside .contentlistheader-mobile.aside--subnav {
    display: none;
  }
}
@media (max-width: 1000px) {
  aside {
    position: fixed;
    z-index: 1;
    background-color: #fff;
    flex: none;
    width: 100%;
    max-width: 100%;
    top: 64px;
    left: 0;
    width: 0;
    bottom: 0;
    transition: width 0.3s;
    overflow: hidden;
  }
  aside .contentlistheader-mobile {
    display: block;
  }
  html.contentmenu-show aside {
    width: 100vw;
  }
}
@media (max-width: 600px) {
  aside {
    top: 48px;
  }
}
aside a {
  color: #4a4a4a;
}
aside a:hover {
  text-decoration: none;
}
aside .manualsidenav {
  transition: transform 0.5s;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 1001px) {
  html.fixed-sidenav aside .manualsidenav {
    position: fixed;
    top: 20px;
  }
  html.fixed-sidenav.fixed-header aside .manualsidenav {
    transform: translate3d(0, 74px, 0);
  }
  html.fixed-sidenav.fixed-header--hide aside .manualsidenav {
    transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 1000px) {
  aside .manualsidenav {
    max-width: 100% !important; /* overwrite inline style */
  }
}
aside nav {
  overflow-y: auto;
  width: 100%;
  padding-right: 30px;
  max-height: calc(100vh - 200px);
  line-height: 115%;
  font-size: 15px;
}
@media (max-width: 1000px) {
  aside nav {
    padding: 0 20px;
    width: 100vw;
  }
  aside .manualsidena--haspdf > nav {
    max-height: calc(100vh - 240px);
  }
}
@media (max-width: 600px) {
  aside .manualsidena--haspdf > nav {
    max-height: calc(100vh - 225px);
  }
}
aside nav > ul > li {
  font-weight: bold;
}
aside nav > ul > li > ul > li {
  font-weight: normal;
}
aside nav > ul > li > ul > li.active a,
aside nav > ul > li > ul > li a:hover {
  color: #3BA1FA;
}
aside nav li > ul {
  padding-left: 15px;
}
aside nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
aside nav li {
  padding-top: 8px;
}
aside nav ul.hide, aside nav li.hide {
  display: none;
}