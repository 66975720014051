html.reference main > .whdoc__hssymbol__container {
  padding-left: 0;
  padding-right: 0;
}
html.reference main > .whdoc__hssymbol__container .heading2,
html.reference main > .whdoc__hssymbol__container .heading3,
html.reference main > .whdoc__hssymbol__container .normal,
html.reference main > .whdoc__hssymbol__container .unordered {
  padding-left: 30px;
  padding-right: 30px;
}
html.reference main > .whdoc__hssymbol__container .unordered .normal {
  padding-left: 0;
  padding-right: 0;
}
html.reference main > .whdoc__hssymbol__container table {
  margin-left: 30px;
  margin-right: 30px;
}
html.reference main > .whdoc__hssymbol__container table pre {
  margin: 0;
}

.whdoc__inlinecode,
.whdoc__inlinecode[class*=language-] {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 2px 5px;
  width: auto;
}

.whdoc__hssymbol__paramdesc,
.whdoc__hssymbol__retvaldesc,
.whdoc__hssymbol__paramcelldesc,
.whdoc__hssymbol__retvalcelldesc {
  margin-left: 20px;
}

.whdoc__objtype ul {
  padding-left: 0;
}