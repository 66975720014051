div.iconinfo {
  font-weight: normal;
  margin-bottom: 3px;
}

div.iconinfo > em {
  font-weight: bold;
}

div.variantcontainer {
  display: inline-block;
}

div.variant {
  display: inline-block;
  padding: 4px 8px 4px 8px;
  text-align: center;
  font-weight: normal;
}

div.variant.color-w {
  background-color: rgb(74, 74, 74);
  color: #ffffff;
}

div.variant > img.icon {
  display: inline-block;
}

/*** SVG VIEWER ***/
div.overlay {
  display: none;
  position: fixed;
  top: 68px;
  left: 313px;
  bottom: 12px;
  right: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999999;
}

div.overlay.active {
  display: block;
}

div.viewer {
  position: relative;
  width: 280px;
  height: 185px;
  top: 220px;
  margin: 0 auto;
  background-color: #FFFFFF;
}

div.viewer > #iconfqn {
  display: inline-block;
  width: 100%;
  height: 24px;
  font-weight: bold;
  padding: 2px 5px 2px 10px;
  background-color: #AAAAAA;
  color: #FFFFFF;
}

div.viewer > #viewerimgholder {
  padding: 10px 5px 10px 5px;
  margin: 5px 5px 5px 5px;
  float: left;
  clear: both;
  width: 150px;
  height: 150px;
  border: 1px solid #F0F0F0;
}

div.viewer > #viewerimgholder > img {
  margin: 0 auto;
}

div.viewer > button {
  float: right;
  clear: left;
  margin-top: -30px;
  margin-right: 5px;
  width: 115px;
  height: 24px;
  cursor: pointer;
}

div.viewer > select {
  width: 115px;
}