/* OLD: */
ul.summary {
  list-style-type: none;
  margin: 0 0 0 -30px;
  padding: 0;
  width: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-flow: row;
  flex-wrap: wrap;
}
ul.summary > li {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
  padding: 0 0 30px 30px;
}
ul.summary > li.summary--withsubitems {
  margin-top: 30px;
  position: relative;
  padding-top: 15px;
}
ul.summary > li.summary--withsubitems::before {
  content: "";
  display: block;
  position: absolute;
  left: 30px;
  right: 0;
  top: 0;
  border-top: 1px solid #c7c7c7;
}
ul.summary > li.summary--withsubitems > a {
  font-family: "Barlow", Sans-Serif, Arial;
  font-size: 20px;
  line-height: 22px;
  display: block;
  color: #010101;
}
ul.summary > li.summary--withsubitems .description {
  display: block;
  font-size: 14px;
  padding: 2px 0 5px;
}
ul.summary > li.summary--withsubitems ul {
  margin: 0;
  list-style-type: none;
  padding: 5px 0 0;
  font-size: 14px;
}
ul.summary > li.summary--withsubitems ul > li {
  padding-top: 5px;
}
ul.summary > li.summary--thumbs {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(1, 1);
  opacity: 1;
}
ul.summary > li.summary--thumbs > a {
  display: block;
  position: relative;
  text-decoration: none;
  width: 100%;
  color: inherit;
  font-family: "Barlow", Sans-Serif, Arial;
  font-size: 20px;
  line-height: 22px;
  transform: translate3d(0, 0, 0);
}
ul.summary > li.summary--thumbs > a .image {
  display: block;
  position: relative;
  background-color: #3BA1FA;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 25%;
  overflow: hidden;
}
ul.summary > li.summary--thumbs > a .image:before {
  content: "";
  display: block;
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1; /* on top */
  transition: opacity 0.3s;
}
ul.summary > li.summary--thumbs > a .image.image--placeholder {
  background-image: url(../../web/img/hare-white.png);
}
ul.summary > li.summary--thumbs > a .image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s, opacity 1s;
}
ul.summary > li.summary--thumbs > a .image:after {
  content: "";
  padding-top: 54.5454545455%;
  display: block;
}
ul.summary > li.summary--thumbs > a:hover .image:before {
  z-index: 1;
  opacity: 0.7;
}
ul.summary > li.summary--thumbs > a:hover .image > img {
  transform: scale(1.1, 1.1);
}
ul.summary > li.summary--thumbs > a:hover .image--placeholder {
  background-size: 40%;
  background-image: url(../../web/img/hare_ani.gif);
}
ul.summary > li.summary--thumbs > a .title {
  padding-top: 10px;
  font-weight: 500;
  display: block;
}
ul.summary > li.summary--thumbs > a .description {
  padding-top: 2px;
  font-weight: 200;
  display: block;
  font-size: 16px;
}
ul.summary > li.summary--thumbs > a .date {
  font-size: 14px;
  color: #999;
}
@media (max-width: 650px) {
  ul.summary > li {
    flex: 1 1 50%;
    max-width: 50%;
  }
}
@media (max-width: 600px) {
  ul.summary {
    display: block;
  }
  ul.summary > li {
    flex: 1 1 100%;
    max-width: 100%;
    min-height: 20vw;
    margin-bottom: 15px;
  }
  ul.summary > li.summary--thumbs a {
    padding-left: calc(20vw + 15px);
  }
  ul.summary > li.summary--thumbs a .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 20vw;
    height: 20vw;
  }
  ul.summary > li.summary--thumbs a .image.image--placeholder {
    background-size: 50%;
  }
  ul.summary > li.summary--thumbs a .image > img {
    height: 100%;
    width: auto;
    left: -9999px;
    right: -9999px;
    margin: 0 auto;
  }
  ul.summary > li.summary--thumbs a:hover .image--placeholder {
    background-size: 60%;
  }
}