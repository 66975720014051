/* OLD: */
body {
  padding-top: 74px; /* space for header*/
}

header {
  height: 74px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20; /* on top of main */
  font-family: "Barlow", Sans-Serif, Arial;
  background-color: #3B3B3B;
  color: #fff;
  transition: top 0.5s;
}
html.fixed-header header {
  position: fixed;
}
header a {
  font-family: inherit;
}
header .centercontainer {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
header .logo {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-size: 26px;
  height: 41px;
  line-height: 36px;
  font-weight: 500;
  white-space: nowrap;
  align-items: center;
  margin-right: 30px;
}
header .logo > span {
  display: inline-block;
  vertical-align: top;
  padding-top: 4px;
}
header .logo > span > small {
  font-weight: 200;
  font-size: inherit;
  line-height: inherit;
  padding-left: 5px;
  color: #979797;
}
header .logo .icon {
  display: inline-block;
  width: 42px;
  height: 36px;
  margin-right: 10px;
  background: transparent url(../web/img/hare-white.png) no-repeat 0 0;
  background-size: contain;
}
header #menuwrapper {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
}
header #menuwrapper .contentmenu-toggle, header #menuwrapper .mobilemenu-toggle {
  color: #fff;
  position: absolute;
  top: 0;
  cursor: pointer;
  display: none;
  width: 48px;
  height: 100%;
  max-height: 64px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}
@media (max-width: 600px) {
  header #menuwrapper .contentmenu-toggle, header #menuwrapper .mobilemenu-toggle {
    width: 36px;
    max-height: 48px;
  }
}
header #menuwrapper .contentmenu-toggle:hover, header #menuwrapper .mobilemenu-toggle:hover {
  background-color: #4a4a4a;
}
header #menuwrapper .contentmenu-toggle .icon, header #menuwrapper .mobilemenu-toggle .icon {
  display: inline-block;
  width: 16px;
  position: relative;
}
header #menuwrapper .contentmenu-toggle {
  right: 57px;
}
html.contentmenu-show header #menuwrapper .contentmenu-toggle {
  background-color: #4a4a4a;
}
@media (max-width: 600px) {
  header #menuwrapper .contentmenu-toggle {
    right: 45px;
  }
}
header #menuwrapper .contentmenu-toggle .icon {
  height: 14px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
header #menuwrapper .contentmenu-toggle .icon::before {
  content: "";
  display: inline-block;
  height: 16px;
  top: -2px;
  left: 2px;
  border-left: 2px solid #3B3B3B;
  position: absolute;
}
header #menuwrapper .contentmenu-toggle .icon::after {
  content: "";
  display: inline-block;
  width: 12px;
  border-top: 2px solid #fff;
  position: absolute;
  top: 4px;
  left: 0;
}
header #menuwrapper .mobilemenu-toggle {
  right: 8px;
}
header #menuwrapper .mobilemenu-toggle .icon {
  height: 0;
  border-bottom: 2px solid #fff;
  transition: transform 0.2s;
}
header #menuwrapper .mobilemenu-toggle .icon::before, header #menuwrapper .mobilemenu-toggle .icon::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 0;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: -6px;
  transition: transform 0.2s;
}
header #menuwrapper .mobilemenu-toggle .icon::after {
  top: 6px;
}
html.mobilemenu-show header #menuwrapper .mobilemenu-toggle .icon {
  transform: rotate(45deg);
}
html.mobilemenu-show header #menuwrapper .mobilemenu-toggle .icon::before {
  transform: rotate(90deg) translate(6px, 0);
}
html.mobilemenu-show header #menuwrapper .mobilemenu-toggle .icon::after {
  transform: rotate(90deg) translate(-6px, 0);
}
header #menuwrapper #mobilemetanav {
  color: #fff;
  border-top: 1px solid #5A5A5A;
  margin: 0 22px;
  padding-left: 38px;
  padding-top: 5px;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  display: none;
}
header #menuwrapper #mobilemetanav a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 3px 10px 3px 30px;
  position: relative;
  margin-top: 15px;
}
header #menuwrapper #mobilemetanav a:hover {
  text-decoration: underline;
}
header #menuwrapper #mobilemetanav a.support > span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #fff;
  border-radius: 100%;
  text-align: center;
}
header #menuwrapper #mobilemetanav a.support > span::after {
  display: inline-block;
  content: "?";
  position: relative;
  top: -2px;
  font-size: 13px;
}
header #menuwrapper #mobilemetanav a > span {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 6px;
}
header #menuwrapper__scrollcontainer {
  position: relative;
  width: 100%;
  height: 100%;
}

#topsearchform {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -17px;
  height: 34px;
  width: 35px;
  overflow: hidden;
  transition: width 0.3s;
}
html.topsearch--active #topsearchform {
  overflow: visible;
}
#topsearchform button {
  display: block;
  cursor: pointer;
  font-size: 18px;
  width: 34px;
  height: 34px;
  padding: 0;
  margin: 0;
  text-align: right;
  background: transparent;
  border: 0 none;
  outline: none;
  position: absolute;
  color: #fff;
  left: 0;
  top: 0;
  transition: opacity 0.2s;
}
html.topsearch--active #topsearchform button {
  color: #4a4a4a;
}
#topsearchform .close {
  width: 34px;
  height: 34px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  cursor: pointer;
  color: #4a4a4a;
  transition: color 0.15s;
  padding: 7px;
  font-size: 20px;
}
#topsearchform .close:hover {
  color: #000;
}
#topsearchform input {
  border: 1px solid #E9E9E9;
  background-color: #F8F8F8;
  font-size: 16px;
  line-height: 20px;
  height: 100%;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 3px;
  outline: none;
  padding: 12px 50px 12px 0;
  opacity: 0;
  transition: all 0.3s;
  overflow: hidden;
}
#topsearchform .wh-autocomplete-values {
  display: none;
}
#topsearchform .wh-autocomplete-values li {
  padding-left: 50px;
}
html.topsearch--active #topsearchform {
  width: 50vw;
}
html.topsearch--active #topsearchform .wh-autocomplete-values, html.topsearch--active #topsearchform .close {
  display: block;
}
html.topsearch--active #topsearchform input {
  opacity: 1;
  padding-left: 50px;
}
@media (min-width: 1001px) {
  #topsearchform button:hover {
    opacity: 0.8;
  }
}

#mainnav {
  height: 74px;
  font-size: 18px;
  line-height: 30px;
  white-space: nowrap;
  transition: opacity 0.2s;
  font-weight: 200;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
   not supported by any browser */
}
#mainnav > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#mainnav > ul > li {
  display: inline-block;
}
#mainnav > ul > li > .children {
  display: none;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: height 0.2s;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: -1px; /* IE11 background render fix: 1px offset */
  height: 0;
}
#mainnav > ul > li > .children ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#mainnav > ul > li > .children li {
  text-transform: none;
  display: inline-block;
  padding: 3px 0;
}
#mainnav > ul > li > .children li a {
  font-weight: 200;
  display: block;
}
#mainnav a {
  text-decoration: none;
  color: inherit;
  transition: color 0.15s;
}
#mainnav > ul > li > a {
  display: inline-block;
  padding: 26px 25px 18px;
  transition: background-color 0.15s;
}
#mainnav > ul > li > a .arrows {
  display: none;
}
#mainnav > ul > li > a:hover, #mainnav > ul > li.active > a {
  background-color: #4A4A4A;
}
#mainnav .children li.active > a {
  font-weight: bold;
}
#mainnav .children a:hover {
  text-decoration: underline;
}

@media (max-width: 1000px) {
  body {
    padding-top: 64px; /* space for header*/
  }
  html.mobilemenu-beforeshow,
  html.contentmenu-show {
    overflow: hidden;
  }
  header.navheader {
    background-color: #3B3B3B;
    height: 64px;
    position: fixed;
  }
  header.navheader .logo {
    color: #fff;
    font-size: 20px;
  }
  header.navheader .logo > .icon {
    margin-top: 4px;
    width: 36px;
    height: 32px;
  }
  header.navheader #menuwrapper__scrollcontainer {
    width: 320px;
    height: calc(100% - 64px);
    position: absolute;
    margin-top: 64px;
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #3B3B3B;
  }
  html.mobilemenu-beforeshow header.navheader #menuwrapper__scrollcontainer {
    display: block;
    right: -320px;
    transition: right 0.2s;
  }
  html.mobilemenu-show header.navheader #menuwrapper__scrollcontainer {
    right: 0;
  }
  header.navheader #menuwrapper {
    position: absolute;
    top: 0;
    left: 0;
  }
  html.mobilemenu-beforeshow header.navheader #menuwrapper {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10; /* on top of rf nav */
  }
  html.mobilemenu-beforeshow header.navheader #menuwrapper::before {
    content: "";
    display: block;
    position: fixed;
    top: 64px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.2s;
  }
  html.mobilemenu-show header.navheader #menuwrapper::before {
    opacity: 0.7;
  }
  header.navheader #menuwrapper #mobilemetanav {
    display: block;
  }
  header.navheader #menuwrapper #mainnav {
    bottom: auto;
    margin-top: 0px;
    height: auto;
    position: relative;
    width: 320px;
    left: 0;
    background-color: transparent;
  }
  header.navheader #menuwrapper #mainnav > ul {
    padding-left: 22px;
  }
  header.navheader #menuwrapper #mainnav li {
    display: block;
  }
  header.navheader #menuwrapper #mainnav > ul > li {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0;
  }
  header.navheader #menuwrapper #mainnav > ul > li > a {
    display: block;
    padding: 2px 0 2px 40px;
    position: relative;
  }
  header.navheader #menuwrapper #mainnav > ul > li > a .arrows {
    color: #f2f2f2;
    display: inline-block;
    position: absolute;
    top: 1px;
    font-size: 24px;
    left: 5px;
    transform-origin: 30% 60%;
    transition: transform 0.2s;
  }
  header.navheader #menuwrapper #mainnav > ul > li.showchildren .arrows {
    transform: rotate(90deg) translate(-5px, 4px);
  }
  header.navheader #menuwrapper #mainnav > ul > li > .children {
    position: relative;
    display: block;
  }
  header.navheader #menuwrapper #mainnav > ul > li > .children ul {
    padding: 0 0 0 35px;
  }
  header.navheader #menuwrapper #mainnav > ul > li.showchildren > .children {
    height: auto;
  }
  header.navheader #menuwrapper #mainnav > ul > li li {
    font-size: 14px;
  }
  header.navheader #menuwrapper #topsearchform {
    width: auto;
    margin: 0;
    top: 0;
    position: relative;
    display: block;
    margin-bottom: 20px;
    height: 44px;
    overflow: visible;
  }
  header.navheader #menuwrapper #topsearchform::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 22px;
    right: 22px;
    border-bottom: 1px solid #fff;
  }
  header.navheader #menuwrapper #topsearchform .wh-autocomplete-values {
    display: block;
    padding-top: 0;
    border-radius: 0;
  }
  header.navheader #menuwrapper #topsearchform .wh-autocomplete-values li {
    padding-left: 62px;
  }
  header.navheader #menuwrapper #topsearchform input {
    color: #fff;
    opacity: 1;
    border-radius: 0;
    width: 100%;
    background-color: transparent;
    border: 0 none;
    padding-right: 67px;
    padding-left: 62px;
  }
  header.navheader #menuwrapper #topsearchform.suggestionsactive input, header.navheader #menuwrapper #topsearchform input:focus {
    background-color: #f8f8f8;
    color: #000;
  }
  header.navheader #menuwrapper #topsearchform.suggestionsactive button, header.navheader #menuwrapper #topsearchform input:focus + button {
    color: #2486DB;
    opacity: 1;
  }
  header.navheader #menuwrapper #topsearchform .close {
    display: none;
  }
  header.navheader #menuwrapper #topsearchform button {
    left: auto;
    padding-top: 10px;
    text-align: left;
    right: 7px;
    color: #fff;
    opacity: 0.8;
  }
  html.mobilemenu-show header.navheader #menuwrapper #mainnav, html.mobilemenu-show header.navheader #menuwrapper .header__topright {
    display: block;
  }
  header.navheader #menuwrapper .contentmenu-toggle {
    display: flex;
  }
  header.navheader #menuwrapper .mobilemenu-toggle {
    display: flex;
  }
  html.mobilemenu-show header.navheader #menuwrapper .mobilemenu-toggle .open {
    display: none;
  }
  html.mobilemenu-show header.navheader #menuwrapper .mobilemenu-toggle .close {
    display: inline-block;
  }
  #mainnav, .header__topright {
    display: none;
  }
}
@media (max-width: 600px) {
  body {
    padding-top: 48px; /* space for header*/
  }
  header.navheader {
    height: 48px;
  }
  header.navheader .logo > .icon {
    margin-top: 6px;
    width: 28px;
    height: 25px;
  }
  header.navheader #menuwrapper #menuwrapper__scrollcontainer {
    height: calc(100% - 48px);
    margin-top: 48px;
  }
  html.mobilemenu-beforeshow header.navheader #menuwrapper::before {
    top: 48px;
  }
}