.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.wh-form__label {
  min-width: 150px;
}

@media (max-width: 505px) {
  /* Order the inputs vertical from their labels */
  .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  /* Give the fields their full width back */
  .wh-form__fields {
    width: 100%;
  }
}
.wh-form {
  /* css styled pulldown */
}
.wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-form__fieldgroup--error .wh-form__pulldown-styled {
  border-color: #e41e00;
}
.wh-form__pulldown-styled {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #999;
  font-size: 18px;
  transition: border-color 0.2s;
  overflow: hidden;
  background-color: #fff;
  height: 33px;
  font-size: 14px;
  line-height: 20px;
  flex: 1;
}
.wh-form__pulldown-styled .arrow {
  font-size: 26px;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -13px;
  color: rgb(0, 29, 96);
}
.wh-form__pulldown-styled:hover {
  border-color: #333;
}
.wh-form__pulldown-styled:after {
  display: block;
  content: "";
  width: 25px;
  position: absolute;
  z-index: 2; /* On top of select */
  right: 28px;
  top: 1px;
  bottom: 1px;
  pointer-events: none;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=1 ); /* IE6-9 */
}
.wh-form__pulldown-styled select {
  position: relative;
  z-index: 1; /* On top of arrow */
  border: 0 none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 33px;
  width: 100%;
  padding: 0 30px 0 10px !important;
}
.wh-form__pulldown-styled select:disabled {
  opacity: 0.4;
}
.wh-form__pulldown-styled select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}